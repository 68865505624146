form input:not([type="checkbox"]):not([type="radio"]) {
  @apply block w-full px-2 h-10 leading-6 border rounded appearance-none;
}

form input[type="checkbox"] {
  @apply block px-2 py-3 leading-6;
}

form input[type="radio"] {
  @apply block px-2 py-3 leading-6;
}

form button[type="submit"] {
  @apply rounded-full border bg-primary text-white p-3;
}

form button[type="submit"]:hover {
  @apply rounded-full bg-white text-primary p-3 border-primary;
}

/* form input {
  @apply text-xs text-white bg-transparent;
  @apply border-white border-opacity-10 rounded;
  @apply px-3 py-2;
  @apply leading-6;
} */

/* .autocomplete-field {
  @apply text-xs;
  @apply border-white border-opacity-10 rounded;
  @apply px-3 py-2;
  @apply leading-6;
} */

/* .react-datepicker-wrapper {
  @apply w-full;
} */
